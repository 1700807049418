import React, {Component} from "react"
import {
	Button,
	Form,
	Message,
	Header
} from 'semantic-ui-react'
import * as styles from './styles/forms.module.css'
import {
	confirmationColor,
} from '../../globalVar'

const Negosyo = require('../../../lib/negosyo')

const required =[
	"name",
	"contact",
	"productName"
]

export default class RequestProduct extends Component {
	state={
		contact:'',
		name:'',
		productName:'',
		notes:'',
		brand:'',
		loggedIn:null, //might not be needed
		user:null,
		loading:false,
		loadingProfile:false, //name
		loadingContact:false, //mobile
		error:'',
		success:null,
		timeOutRef:null,
		errorMessage: {
			name:null,
			contact:null,
			productName:null
		}
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOutRef)
	}

	componentDidMount() {
		const {
			location
		} = this.props
		if(location) {
			if(location.state) {
				var reqnotes = "";
				if(location.state.requestNotes) {
					reqnotes = location.state.requestNotes;
				}
				if(location.state.productName) {
					this.setState({
						productName:location.state.productName,
						notes: reqnotes
					})
				}
			}
		}
		Negosyo.isLoggedIn()
		.then((isLoggedIn)=>{
			if(isLoggedIn) {
				//Negosyo get customer name/contact and set that as initial values for the form
				this.setState({loggedIn:true})
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					this.setState({
						user:user,
						loadingProfile:true,
						loadingContact:true
					})
					Negosyo.getUserProfile(user.username).then((response)=>{
						if(response.status==="OK") {
							this.setState({
								name:response.data.name,
								loadingProfile:false,
							})
						}else {
							this.setState({
								loadingProfile:false
							})
						}

					}).catch((err)=>{
						this.setState({
							loadingProfile:false
						})
					})

					Negosyo.getContactList(user.username).then((response)=> {
						if(response.status==="OK") {
							var responseidx = 0;
							var primarymobile = "";
							while (responseidx < response.data.length) {
								if( response.data[responseidx].contact.indexOf("@") < 0 ) {
									if (primarymobile.length < 1 || response.data[responseidx].contact.verified === true) {
										primarymobile = response.data[responseidx].contact;
									}
								}
								responseidx = responseidx + 1;
							}
							this.setState({
								contact:primarymobile
							});
							this.setState({loadingContact:false})
						}else {
							this.setState({loadingContact:false})
						}
					}).catch((err)=>{
						this.setState({loadingContact:false})
					})

				}).catch((err)=>{

				})
			}
		})

	}

	handleInputChange = (e, { name, value }) => {
		if (name === "contact" || name === "email") {
			value = value.replace(/\s/g,"");
		}

		this.setState({ [name]: value })
		let isRequired = false
		for( let i = 0; i<required.length; i++ ) {
			if(name === required[i]) {
				isRequired=true
				break
			}
		}
		if(	value==='' && isRequired){
			const message = " must not be blank"
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		} else {
			const message = null
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		}
	}

	validateInput = (states) => {
		// Copied from CartSummary
		for( let key2 of Object.keys(states) ) {
			this.setState({
				[key2]:this.state[key2].trim()
			})
			states[key2] = states[key2].trim()
		}

		let check = true
		for( let key of Object.keys(states) ) {
			if(
				states[key] === ''
			) {
				check = false
				this.setState(prevState => ({
					errorMessage: {
						...prevState.errorMessage,
						[key] : " must not be blank"
					}
				}))
			} else if(key==="contact") {
				const pattern = /^(0|\+63)(8|9)([0-9]{9})$/g
				const result = pattern.test(states[key])
				if(!result) {
					check = false
					this.setState(prevState => ({
						errorMessage: {
							...prevState.errorMessage,
							contact : "must be a valid mobile number"
						}
					}))
				}
			}
		}
		return check
	}

	handleSubmit = () => {

		const temp = {};
		for( let i = 0; i<required.length; i++ ) {
			temp[required[i]] = this.state[required[i]]
		}
		const check = this.validateInput(temp)
		if(check) {
			const submitData = {
				Name:this.state.name,
				Contact:this.state.contact,
				"Product Details": {
					Name:this.state.productName,
					Brand:this.state.brand,
					Notes:this.state.notes
				}

			}

			Negosyo.requestProduct(submitData)
			.then((result)=>{
				if(result.status==="OK") {
					this.setState({
						name:"",
						contact:"",
						productName:"",
						brand:"",
						notes:"",
						success:"Successfully Submitted request",
						loading:false
					})
					const timeOut = setTimeout(()=>{
						this.setState({
							success:""
						})
					}, 5000)
					this.setState({
						timeOutRef:timeOut
					})
				}else {
					this.setState({
						success:"Unable to send request",
						loading:false
					})
				}


			}).catch((err)=>{
				this.setState({
					error:"Unable to send request",
					loading:false
				})
			})


		}else {
			this.setState({loading:false})
		}

	}

	cleanUp = (event) => {
		event.preventDefault() //not sure why this is was added
		const temp = {
			name:this.state.name,
			contact:this.state.contact,
			productName:this.state.productName,
			brand:this.state.brand,
			notes:this.state.notes
		}
		this.setState({loading:true})
		const tempState = {}
		for( let key of Object.keys(temp) ) {
			if(
				this.state[key] !== ''
			){
				tempState[key] = this.state[key].trim();
				tempState[key] = tempState[key].replace(/ +/g, " ");
			}
		}
		this.setState(
			tempState
		, this.handleSubmit)
		/*
			needed to do a seperate cleanup and handleDefaultValue because setState is asynchronous and will
			go to the next Step without finishing updating state, so needed to do a callback after
			setState sets the states to make sure state is updated appropriately
		*/
	}
	render() {
		const {
			success,
			error,
			loading,
			contact,
			name,
			productName,
			notes,
			brand,
			errorMessage,
			loadingContact,
			loadingProfile
		} = this.state
		const {
			handleInputChange,
			cleanUp
		} = this
		return (
			<React.Fragment>
				<Header className={styles.header}>
					Request a Product
				</Header>
				<Form >
					<Form.Input
						label="Name*"
						value={name}
						name='name'
						error={errorMessage.name}
						onChange={handleInputChange}
						className={`${styles.form} ${styles.formShortened}`}
						disabled={loadingProfile}
						loading={loadingProfile}
					/>
					<Form.Input
						label="Mobile #*"
						placeholder="(e.g. 0917XXXXXXX, +63917XXXXXXXX)"
						value={contact}
						name='contact'
						error={errorMessage.contact}
						onChange={handleInputChange}
						className={`${styles.form} ${styles.formShortened}`}
						disabled={loadingContact}
						loading={loadingContact}
					/>
					<Form.Input
						label="Product Name*"
						value={productName}
						name='productName'
						error={errorMessage.productName}
						onChange={handleInputChange}
						className={`${styles.form} ${styles.formShortened}`}
					/>
					<Form.Input
						label="Product Brand"
						value={brand}
						name='brand'
						onChange={handleInputChange}
						className={`${styles.form} ${styles.formShortened}`}
					/>
					<Form.Input
						label="Additional Notes/Details"
						value={notes}
						name='notes'
						onChange={handleInputChange}
						className={`${styles.form} ${styles.formShortened}`}
					/>
					{success &&
						<Message positive className={styles.msg}>
							{success}
						</Message>
					}
					{error &&
						<Message negative className={styles.msg}>
							{error}
						</Message>
					}
					<Button
						color={confirmationColor}
						loading={loading}
						disabled={loading}
						onClick={cleanUp}
						className={styles.form}
					>
						Send Request
					</Button>
				</Form>
			</React.Fragment>
		)
	}
}